import React from 'react'
import { Card, Form } from 'react-bootstrap'
import { MultiDropdownList, DataSearch } from '@appbaseio/reactivesearch'

const defaultQuery = (slug) => {
  return {
    "query": {
      "match": {
        "facilities.slug.keyword": {
          "query": slug
        }
      }
    }
  }
}

const Filter = ({ slug }) => {

  return (
    <Card body>
      <h6 className="font-weight-bold mb-4">FILTER</h6>
      <Form>
        <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">Search</Form.Label>
          <DataSearch
            componentId="search"
            dataField={["name"]}
            showIcon={false}
            URLParams={true}
            placeholder="Search specific doctors"
            defaultQuery={() => defaultQuery(slug || "pldt")}
            className="w-100 pr-1"
          />
        </Form.Group>

        <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">Specializations</Form.Label>
          <MultiDropdownList
            defaultQuery={() => defaultQuery(slug || "pldt")}
            placeholder="Select Specializations"
            componentId="specialization"
            dataField="specialization.keyword"
            URLParams={true}
            size={1000}
          />
        </Form.Group>

        {/* <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">HMO</Form.Label>
          <MultiDropdownList
            placeholder="Select HMOs"
            defaultQuery={() => defaultQuery(slug || "metro-antipolo-hospital-and-medical-center")}
            componentId="SearchHmo"
            dataField="hmo.name.keyword"
            size={1000}
          />
        </Form.Group>

        <Form.Group controlId="SearchDepartment">
          <Form.Label className="font-weight-bold">Cities</Form.Label>
          <MultiDropdownList
            defaultQuery={() => defaultQuery(slug || "metro-antipolo-hospital-and-medical-center")}
            placeholder="Select Cities"
            componentId="SearchCity"
            dataField="facilities.city.keyword"
            size={1000}
          />
        </Form.Group> */}

      </Form>
    </Card>
  )
}

export default Filter


  // <Form.Group controlId="AvailableDays">
  //   <Form.Label className="font-weight-bold">Available days</Form.Label>
  //   <SingleRange
  //     componentId="PriceSensor"
  //     dataField="price"
  //     data={
  //       [{"start": 0, "end": 10, "label": "Monday"},
  //         {"start": 11, "end": 20, "label": "Tuesday"},
  //         {"start": 21, "end": 50, "label": "Wednesday"},
  //         {"start": 51, "end": 1000, "label": "Thursday"},
  //         {"start": 51, "end": 1000, "label": "Friday"},
  //         {"start": 51, "end": 1000, "label": "Saturday"},
  //         {"start": 51, "end": 1000, "label": "Sunday"}]
  //     }
  //     defaultValue="Monday"
  //     showRadio={true}
  //     showFilter={true}
  //     URLParams={false}
  //   />
  // </Form.Group>

  // <Form.Group controlId="AvailableDays">
  //   <Form.Label className="font-weight-bold">Preferred time</Form.Label>
  //   <Form.Row>
  //     <Form.Group as={Col} sm={6} controlId="formGridEmail">
  //       <Form.Control type="time"/>
  //     </Form.Group>

  //     <Form.Group as={Col} sm={6} controlId="formGridPassword">
  //       <Form.Control type="time"/>
  //     </Form.Group>
  //   </Form.Row>
  //   <Button variant="secondary" size="lg" block type="submit" className="mt-4">Search</Button>
  // </Form.Group>



  // <ButtonToolbar>
  //   <ToggleButtonGroup type="checkbox" className="flex-wrap">
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="monday"  >M</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="tuesday">T</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="wednesday">W</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="thursday">TH</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="friday">F</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="saturday">S</ToggleButton>
  //     <ToggleButton variant="outline-secondary" size="lg" className="custom-checkbox-button m-1" value="sunday">SU</ToggleButton>
  //   </ToggleButtonGroup>
// </ButtonToolbar>
//
//
