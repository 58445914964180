import React, { Component } from 'react'
import { Home } from 'react-feather'
import { SEO, Layout, HMOSlider } from '../../components/common'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap'
import { ReactiveBase, ReactiveList } from '@appbaseio/reactivesearch'
import Filter from './Filter'
import PractitionerCard from './PractitionerCard'

class OurDoctor extends Component {
  renderData(data, slug) {
    return <PractitionerCard key={data._id} data={data} facility_slug={slug} />
  }

  defaultQuery(slug) {
    return {
      "query": {
        "match": {
          "facilities.slug.keyword": {
            "query": slug
          }
        }
      }
    }
  }

  render() {
    const data = this.props.pageContext
    const { slug, favicon } = data.topNav
    return (
      <>
      <SEO title="Our Doctors" favicon={favicon} />
      <Layout activeNav='our-doctors' data={data.topNav}>
        <Container fluid className="my-4 md-mt-10 sm-mt-7">
          <Breadcrumb className="breadcrumb-wrapper mx-0">
            <Breadcrumb.Item href="/">
              <Home className="text-violet mr-2" size={14} />
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Search Doctor</Breadcrumb.Item>
          </Breadcrumb>

          <ReactiveBase
            app="practitioners"
            url={process.env.ES_URL}
          >
            <section className="reactive-search">
              <Row>
                <Col xs={12} sm={12} md={4} lg={2} xl={2} className="d-none d-md-block my-3">
                  <Filter slug={slug}/>
                </Col>

                <Col xs={12} sm={12} md={8} lg={9} xl={9}>
                  <Row className="d-flex justify-content-center align-items-center h-100">
                    <ReactiveList
                      componentId="ResultList"
                      dataField="name"
                      className="result-list-container custom-result-list"
                      size={10000}
                      dataField="last_name.keyword"
                      sortBy="asc"
                      defaultQuery={() => this.defaultQuery(slug || "pldt")}
                      react={{
                        and: ["search", "specialization"]
                      }}
                      renderItem={data => this.renderData(data, slug)}
                      innerClass={{
                        listItem: 'row',
                      }}
                      renderNoResults={() =>
                          <div className="no-data-available">
                            <div className="data-container">
                              <img src="https://s3-ap-southeast-1.amazonaws.com/seeyoudoc-mobile/version+3/user.svg" alt="user" className="img-small" />
                              <p className="text-dark">There are no data available. </p>
                            </div>
                          </div>
                      }
                    />
                </Row>
              </Col>
            </Row>
          </section>
        </ReactiveBase>
      </Container>

      <HMOSlider data={data.accreditedHmos}/>
    </Layout>
      </>
    )
  }
}
export default OurDoctor 
