import React, { useState, Fragment} from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import { Col, Card, Image,  Modal, ButtonToolbar, Button, Form } from 'react-bootstrap'

const PractitionerCard = ({ data, facility_slug }) => {
  const [isModal, setModal] = useState(false)
  const [newData] = useState(handelData())
  const handleClose = () => setModal(false)
  const handleShow = () => setModal(true)
  const clinic = data.practices === [] || data.practices === undefined ? [] : data.practices.filter(e => e.facility_slug === facility_slug)
  const clinic_services = clinic.map(e => e.clinic_services).flat()

  const [ clinic_slug, setClinicSlug ] = useState()
  const [ service_id, setServiceId ] = useState()

  const handleChange = (event) => {
    setClinicSlug(event.target.value)
  }

  const handleServiceChange = (event) => {
    setServiceId(event.target.value)
  }


  function handelData(){
    return Object.assign(data, {facility_slug: facility_slug})
  }

  const has_one_clinic = clinic.length === 1

  const parseClinicSlug = () => {
    return has_one_clinic ? clinic[0].clinic_slug : clinic_slug
  }

  return(
    <Fragment>
      <Col sm={12} md={12} lg={4} className="my-3 w-100">
        <Card className="w-100">
          <Card.Body className="d-flex flex-column justify-content-center align-items-center w-100">
            <Image src={data.avatar} alt="images-avatar" className="rounded-circle avatar-mmd mb-3"/>
            <h6 className="font-weight-bold">{data.prefix} {data.name}</h6>
            <p className="text-muted">{data.specialization}</p>
            <ButtonToolbar className="d-flex flex-row justify-content-between mt-3 w-100">
              <Link 
                className="btn btn-outline-primary text-nowrap w-46 mr-2 d-flex align-items-center justify-content-center"
                to={`/doctor/${data.slug}`}
                state={newData}
              >
                SCHEDULE
              </Link>
              <Button variant="primary" onClick={handleShow} className="btn btn-primary text-nowrap w-46">
                BOOK
              </Button>
            </ButtonToolbar>
          </Card.Body>
        </Card>
      </Col> 

      <Modal show={isModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Choose a clinic</Modal.Title>
        </Modal.Header>
        <Modal.Body className="clinic-modal">
          <Form>
            { !has_one_clinic?
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Clinic Name<span className="text-danger">*</span></Form.Label>
                  <Form.Control as="select" className="selectpicker show-menu-arrow" onChange={handleChange}>
                    <option defaultValue={clinic_slug} disabled hidden>Choose a clinic</option>
                    {clinic.map((item, i) => <option key={i} value={item.clinic_slug}>{item.clinic_name}</option> )}	
                  </Form.Control>
                </Form.Group>
              : <Fragment /> 
            }
            <Form.Group controlId="exampleForm.ControlSelect2">
              <Form.Label>Clinic Service<span className="text-danger">*</span></Form.Label>
              <Form.Control as="select" className="selectpicker show-menu-arrow" onChange={handleServiceChange}>
                <option defaultValue={service_id} disabled hidden>Choose a clinic service</option>
                {clinic_services.map((item, i) => <option key={i} value={item.id}>{item.name}</option> )}	
              </Form.Control>
            </Form.Group>
            <ButtonToolbar className="justify-content-end w-100 mt-3">
              <Button variant="light" className="border mr-3" onClick={handleClose}>Cancel</Button>
              <a 
                href={process.env.SYD_URL + "/book-appointment/" + data.slug + "/" + parseClinicSlug() + "/service/" + service_id}
                className={clinic_slug === undefined && service_id === undefined ? 'btn btn-primary disabled' : "btn btn-primary"}
              >
                PROCEED TO BOOKING
              </a>
            </ButtonToolbar>
          </Form>
        </Modal.Body>
      </Modal>
    </Fragment>
  )
}

PractitionerCard.propTypes = {
  data: PropTypes.shape({
    avatar: PropTypes.string,
    prefix: PropTypes.string,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired
}

export default PractitionerCard


// <Button 
//   as={Link}
//   to={"/doctor/"+data.slug}
//   variant="outline-primary" 
//   className="text-nowrap w-46 mr-2"
// >
//   SCHEDULE
// </Button>
